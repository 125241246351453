import { useState } from 'react'
import * as Yup from 'yup'
import { Link as RouterLink } from 'react-router-dom'
// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// routes
import { PATH_AUTH } from '../../routes/paths'
// components
import FormProvider, { RHFTextField } from '../../components/hook-form'
import useAuthLoginForm from './useAuthLoginForm'
import Iconify from 'src/components/iconify/Iconify'

// ----------------------------------------------------------------------

type FormValuesProps = {
  phoneNumber: string
  password: string
  afterSubmit?: string
}

export default function AuthLoginForm() {
  const [showPassword, setShowPassword] = useState(false)

  const { handleLogin } = useAuthLoginForm()

  const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('Số điện thoại không được để trống'),
    password: Yup.string().required('Mật khẩu không được để trống'),
  })

  const defaultValues = {
    phoneNumber: '',
    password: '',
  }

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods

  const onSubmit = (data: FormValuesProps) => {
    handleLogin(data)
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField name="phoneNumber" label="Số điện thoại" />

        <RHFTextField
          name="password"
          label="Mật khẩu"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          component={RouterLink}
          to={PATH_AUTH.resetPassword}
          variant="body2"
          color="inherit"
          underline="always"
        >
          Quên mật khẩu?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{
          bgcolor: 'text.primary',
          color: theme =>
            theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
          '&:hover': {
            bgcolor: 'text.primary',
            color: theme =>
              theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
          },
          mb: 7,
        }}
      >
        Đăng nhập
      </LoadingButton>
    </FormProvider>
  )
}
