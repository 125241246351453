
export const REST_API_AUTH = {
  LOGIN: {
    uri: '/api/admin/auth/login',
    method: 'POST',
  },
  GET_PROFILE: {
    uri: '/api/admin/profile/get-profile',
    method: 'GET',
  },
  UPDATE_PROFILE: {
    uri: '/api/admin/profile/update-profile',
    method: 'POST',
  },
  UPDATE_IMAGE_PROFILE: {
    uri: '/api/admin/profile/upload-image-avatar',
    method: 'POST',
  },
  LOGOUT: {
    uri: '/api/admin/auth/logout',
    method: 'POST',
  },
}

export const REST_API_GROUPS = {
  GET_LIST: {
    uri: '/api/booking/list-group',
    method: 'POST',
  },
  CREATE: {
    uri: '/api/booking/create-group',
    method: 'POST',
  },
  UPDATE: {
    uri: '/api/booking/update-group',
    method: 'POST',
  },
  UPDATE_IMAGE: {
    uri: '/api/booking/upload-image-group',
    method: 'POST',
  },
  DELETE_GROUP: {
    uri: '/api/booking/delete-group',
    method: 'POST',
  },
  GET_LIST_PHARMACIST: {
    uri: '/api/admin/shopadmin/listpharmacist',
    method: 'POST',
  },
  GET_LISTPHAMARCIST: {
    uri: '/api/admin/shopadmin/listpharmacist',
    method: 'POST',
  },
  GET_MY_SHOP: {
    uri: '/api/admin/shopadmin/getmyshop',
    method: 'POST',
  },
}

export const REST_API_APPROVE = {
  GET_LIST: {
    uri: '/api/booking/list-approve',
    method: 'POST',
  },
  GET_DRIVER_DETAIL: {
    uri: '/api/booking/driver-info',
    method: 'POST',
  },
  APPROVE_DRIVER: {
    uri: '/api/booking/approve',
    method: 'POST',
  },
  DELETE_APPROVE: {
    uri: '/api/booking/delete-approve',
    method: 'POST',
  },
}

export const REST_API_DRIVER = {
  GET_LIST: {
    uri: '/api/booking/list-driver-approve',
    method: 'POST',
  },
}

export const REST_API_PAYMENT = {
  GET_LIST_WITHDRAW: {
    uri: '/api/payment/list-withdraw',
    method: 'POST',
  },
  VERIFY_WITHDRAW: {
    uri: '/api/payment/verify-withdraw',
    method: 'POST',
  },
}

