// @mui
import {
  Stack,
  InputAdornment,
  TextField,
  MenuItem,
  Button,
} from '@mui/material'
// components
import Iconify from 'src/components/iconify/Iconify'
import { useLocales } from 'src/locales'

// ----------------------------------------------------------------------
type Props = {
  groupId: string
  listGroup: any[]
  filterName: string
  isFiltered: boolean
  onResetFilter: VoidFunction
  onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeGroup: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function ApproveWithdrawalTableToolbar({
  groupId,
  listGroup,
  isFiltered,
  filterName,
  onFilterName,
  onResetFilter,
  onChangeGroup,
}: Props) {
  const { translate } = useLocales()
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <TextField
        fullWidth
        select
        label={translate('group')}
        value={groupId}
        onChange={onChangeGroup}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: 300,
              },
            },
          },
        }}
        sx={{
          maxWidth: { sm: 300 },
          textTransform: 'capitalize',
        }}
      >
        {listGroup.map(group => (
          <MenuItem
            key={group.groupId}
            value={group.groupId}
            sx={{
              mx: 1,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {group.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        placeholder={translate('approveSection.search') + '...'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          {translate('approveSection.clear')}
        </Button>
      )}
    </Stack>
  )
}
