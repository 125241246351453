import { Navigate, useRoutes } from 'react-router-dom'
// auth
import AuthGuard from '../auth/AuthGuard'
import GuestGuard from '../auth/GuestGuard'
// layouts
// import MainLayout from '../layouts/user';
import CompactLayout from '../layouts/compact'
import AdminLayout from '../layouts/admin'
// config
import { PATH_AFTER_LOGIN } from '../config/config-global'
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  AccountPage,
  // Admin
  GroupPage,
  ApprovePage,
  ApproveDetailPage,
  DriverPage,
  //
  Page500,
  Page403,
  Page404,
  MaintenancePage,
} from './elements'
import ComingSoonPage from 'src/pages/ComingSoonPage'
import ApproveWithdrawalPage from 'src/pages/admin/approve-withdrawal/ApproveWithdrawalPage'

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
    // Admin
    {
      path: '',
      element: (
        <AuthGuard>
          <AdminLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'list-group', element: <GroupPage /> },
        { path: 'approve', element: <ApprovePage /> },
        { path: 'approve-withdrawal', element: <ApproveWithdrawalPage /> },
        { path: 'driver/detail', element: <ApproveDetailPage /> },
        { path: 'driver', element: <DriverPage /> },
      ],
    },
    {
      path: 'user',
      element: (
        <AuthGuard>
          <AdminLayout />
        </AuthGuard>
      ),
      children: [{ path: 'account', element: <AccountPage /> }],
    },

    // Compact
    {
      element: <CompactLayout />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
