import { REST_API_APPROVE } from '../api_paths'
import { InstanceAxios } from '../config/axios/admin'


export const getListApprove = (body?: any) => {
  const route: string = REST_API_APPROVE.GET_LIST.uri
  return InstanceAxios.post(route, body)
}

export const getDriverDetail = (filter?: any) => {
  const route: string = REST_API_APPROVE.GET_DRIVER_DETAIL.uri
  return InstanceAxios.post(route, filter)
}

export const approveDriver = (filter?: any) => {
  const route: string = REST_API_APPROVE.APPROVE_DRIVER.uri
  return InstanceAxios.post(route, filter)
}

export const deleteApprove = (filter?: any) => {
  const route: string = REST_API_APPROVE.DELETE_APPROVE.uri
  return InstanceAxios.post(route, filter)
}
