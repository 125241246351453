import { useMutation } from 'react-query'
import { getListWithdraw, verifyWithdraw } from '../api_functions/payment'


  export function useGetListWithdraw() {
    return useMutation(getListWithdraw)
  }

  export function useVerifyWithdraw() {
    return useMutation(verifyWithdraw)
  }

