import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
//
import { setSession } from "./utils";
import {
  ActionMapType,
  AuthStateType,
  AuthUserType,
  JWTContextType,
} from "./types";
import { useLogin, useLogout, useProfile } from "src/api/hooks/admin.query";
import {
  getAuthTokenAdmin,
  getRefreshTokenAdmin,
  removeAuthTokenAdmin,
  removeRefreshTokenAdmin,
  saveRefreshTokenAdmin,
  setAuthTokenAdmin,
} from "src/api/config/token";
import { logDev } from "src/utils";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "src/routes/paths";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = "INITIAL",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  LOGOUT = "LOGOUT",
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    profile: AuthUserType;
  };
  [Types.LOGIN]: {
    profile: AuthUserType;
  };
  [Types.REGISTER]: {
    profile: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  profile: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      profile: action.payload.profile,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      profile: action.payload.profile,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      profile: action.payload.profile,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const loginRequest = useLogin();
  const profileRequest = useProfile();
  const logoutRequest = useLogout();
  const navigate = useNavigate();

  const handleUpdateProfile = useCallback(async () => {
    try {
      const res: any = await profileRequest.mutateAsync();
      const data = { ...res };
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: true,
          profile: data?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          profile: null,
        },
      });
    }
  }, [dispatch, profileRequest]);

  const initialize = useCallback(async () => {
    const tokenAuth = getAuthTokenAdmin();
    if (tokenAuth !== "") {
      await handleUpdateProfile();
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          profile: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (data: any) => {
      loginRequest.mutate({
        data,
      });
    },
    [loginRequest]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    const refreshToken = getRefreshTokenAdmin();
    await logoutRequest.mutateAsync({ refreshToken });
    removeAuthTokenAdmin();
    removeRefreshTokenAdmin();
    navigate(PATH_AUTH.login);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  useEffect(() => {
    if (loginRequest.isSuccess && loginRequest.data != null) {
      const data = loginRequest.data?.data;
      setAuthTokenAdmin(data.accessToken ?? "");
      saveRefreshTokenAdmin(data.refreshToken ?? "");
      handleUpdateProfile();
    }
  }, [loginRequest.isSuccess]);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      profile: state.profile,
      method: "jwt",
      login,
      loginWithGoogle: () => {},
      loginWithGithub: () => {},
      loginWithTwitter: () => {},
      logout,
      updateProfile: handleUpdateProfile,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.profile,
      login,
      logout,
      handleUpdateProfile,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
