import { useMutation } from 'react-query'
import { createGroup, deleteGroup, getListGroups, updateGroup, updateGroupImage, } from '../api_functions/groups'


  export function useGetListGroup() {
    return useMutation(getListGroups)
  }

  export function useCreateGroup() {
    return useMutation(createGroup)
  }

  export function useUpdateGroup() {
    return useMutation(updateGroup)
  }

  export function useUpdateGroupImage() {
    return useMutation(updateGroupImage)
  }
  
  export function useDeleteGroup() {
    return useMutation(deleteGroup)
  }
