
import { StorageService } from "../storage"

// === Logout remove all sesstion
export const removeData = () => {
  StorageService.destroyData()
}

export const removeAll = () => {
  StorageService.destroyAll()
}
// === Token Auth Admin

export const getAuthTokenAdmin = () : string => {
  try {
    if (StorageService.has('AUTH_TOKEN_PARTNER')) {
      return StorageService.get('AUTH_TOKEN_PARTNER')
    }
    return ''
  } catch (e) {
    return ''
  }
}

export const setAuthTokenAdmin = (token: string) => {
  StorageService.set('AUTH_TOKEN_PARTNER', token)
}

export const removeAuthTokenAdmin = () => {
  if (StorageService.has('AUTH_TOKEN_PARTNER')) {
    StorageService.remove('AUTH_TOKEN_PARTNER')
  }
}

// === RefreshToken Partner
export const getRefreshTokenAdmin = () => {
  if (StorageService.has('REFRESH_TOKEN_PARTNER')) {
    return StorageService.get('REFRESH_TOKEN_PARTNER')
  }
  return undefined
}
export const saveRefreshTokenAdmin = (refreshToken: string) => {
  StorageService.set('REFRESH_TOKEN_PARTNER', refreshToken)
}
export const removeRefreshTokenAdmin = () => {
  if (StorageService.has('REFRESH_TOKEN_PARTNER')) {
    StorageService.remove('REFRESH_TOKEN_PARTNER')
  }
}

// === TokenFCM
export const getTokenFCM = () => {
  if (StorageService.has('TOKEN_FCM')) {
    return StorageService.get('TOKEN_FCM')
  }
  return undefined
}
export const saveTokenFCM = (tokenFcm: string) => {
  StorageService.set('TOKEN_FCM', tokenFcm)
}
export const removeTokenFCM = () => {
  if (StorageService.has('TOKEN_FCM')) {
    StorageService.remove('TOKEN_FCM')
  }
}
