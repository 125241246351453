// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vi = {
  demo: {
    title: `Vietnamese`,
    introduction: `Lorem Ipsum chỉ đơn giản là văn bản giả của ngành in ấn và sắp chữ. Lorem Ipsum đã trở thành văn bản giả tiêu chuẩn của ngành kể từ những năm 1500, khi một nhà in không xác định lấy một dãy loại và xáo trộn nó để tạo thành một cuốn sách mẫu. Nó đã tồn tại không chỉ năm thế kỷ, mà còn là bước nhảy vọt trong lĩnh vực sắp chữ điện tử, về cơ bản vẫn không thay đổi. Nó được phổ biến vào những năm 1960 với việc phát hành các tờ Letraset chứa các đoạn Lorem Ipsum, và gần đây hơn là với phần mềm xuất bản trên máy tính để bàn như Aldus PageMaker bao gồm các phiên bản của Lorem Ipsum.`,
  },
  docs: {
    hi: `Chào`,
    description: `Cần giúp đỡ？\n Vui lòng xem tài liệu của chúng tôi.`,
    documentation: `Tài liệu`,
  },
  navMenu: {
    dashboard: 'Dashboard',
    groupManagement: 'Quản lý nhóm'
  },
  group: 'Nhóm',
  approve: 'Phê duyệt',
  driverManagement: 'Quản lý tài xế',
  Pending: 'Đang chờ',
  InActive: 'Từ chối',
  delete: 'Xóa',
  information: 'Thông tin cá nhân',
  gender: 'Giới tính',
  male: 'Nam',
  female: 'Nữ',
  groups: {
    groupList: 'Danh sách nhóm',
    addGroup: 'Thêm nhóm',

    name: 'Tên nhóm',
    status: 'Trạng thái',
    fee: 'Phí duy trì tháng',
    feeFirst: 'Phí tham gia lần đầu',
    feeHybrid: 'Khấu trừ xe ghép',
    feeFull: 'Khấu trừ bao xe',
    feeDelivery: 'Khấu trừ giao hàng',
    numberMembers: 'Số lượng thành viên',

    createGroupTitle: 'Thêm nhóm',
    updateGroupTitle: 'Cập nhật nhóm',

    description: 'Mô tả nhóm',
    content: 'Nội dung nhóm',
    regulations: 'Quy định nhóm',
    groupImage: 'Hình ảnh',
    address: 'Địa chỉ',
    province: 'Tỉnh/Thành phố',
    district: 'Quận/Huyện',
    wards: 'Phường/Xã',

    alertCreateGroupSuccess: 'Tạo nhóm thành công',
    alertUpdateGroupSuccess: 'Cập nhật nhóm thành công',

    alertFieldRequired: 'Trường này không được để trống',
    alertFieldIsNumber: 'Trường này phải là số',
    fieldIsInteger: 'Trường này phải là số nguyên',
    fieldNumberIsMin: 'Giá trị không được nhỏ hơn {{number}}',
    fieldNumberIsMax: 'Giá trị không được lớn hơn {{number}}',
    alertShopImageRequired: 'Ảnh nhóm không được để trống',

    confirmDelete: 'Bạn có muốn xoá nhóm này không?',
    confirmTitle: 'Xác nhận',
    alertDeleteSuccess: 'Xoá nhóm thành công',
    alertDeleteError: 'Xoá nhóm thất bại',
    create: 'Tạo',
    update: 'Cập nhật',
    close: 'Đóng',
  },
  userDetail: {
    liveAt: 'Sống ở',
    addressAt: 'Địa chỉ ở',
    accountInformation: 'Thông tin tài khoản',
    information: 'Thông tin cá nhân',
    numPoint: 'Số điểm',
    rate: 'Đánh giá',
    workplace: 'Làm việc tại',
  },
  accountPage: {
    firstName: 'Họ',
    lastName: 'Tên',
    birthdate: 'Ngày sinh',
    gender: 'Giới tính',
    avatar: 'Ảnh đại diện',
    info: 'Mô tả',
    address: 'Địa chỉ',
    email: 'Email',
    phoneNumber: 'Số điện thoại',
    save: 'Lưu thay đổi',
    General: 'Thông tin cá nhân',
    changePassword: 'Thay đổi mật khẩu',
    oldPassword: 'Mật khẩu cũ',
    newPassword: 'Mật khẩu mới',
    confirmNewPassword: 'Xác nhận mật khẩu mới',
    exp: 'Số năm kinh nghiệm',
    Updatesuccess: 'Cập nhật thành công',

    allowed: 'Cho phép định dạng *.jpeg, *.jpg, *.png, *.gif',
    unFomat: 'Không đúng định dạng',
  },
  approveSection: {
    name: 'Tên tài xế',
    groupName: 'Tên nhóm',
    free: 'Phí tham gia',
    maintainingFee: 'Phí duy trì',
    status: 'Trạng thái',
    point: 'Số điểm',
    dateTime: 'Ngày tạo',
    bankAccountNumber: 'Số tài khoản',
    bankAccountOwnerName: 'Tên tài khoản',
    bankName: 'Ngân hàng',

    list: 'Danh sách phê duyệt',

    pending: 'Đang chờ',
    approve: 'Phê duyệt',
    reject: 'Từ chối',
    rejected: 'Đã từ chối',
    active: 'Đã kích hoạt',
    delete: 'Xoá',
    search: 'Tìm kiếm',
    clear: 'Dọn',
    approved: 'Đã phê duyệt',

    licenseCertificate: 'Bằng cấp',
    businessCertificate: 'Giấy phép hoạt động',
    shopInformation: 'Thông tin nhà thuốc',

    confirmTitle: 'Xác nhận',
    confirmApprove: 'Bạn có muốn phê duyệt người này không?',
    confirmReject: 'Bạn có muốn từ chối phê duyệt này không?',
    confirmDelete: 'Bạn có muốn xoá phê duyệt này không?',
    confirmDeleteAll: 'Bạn có muốn xoá những phê duyệt này không?',
    alertApproveSuccess: 'Phê duyệt thành công',
    alertRejectSuccess: 'Từ chối thành công',
    alertDeleteSuccess: 'Xoá phê duyệt thành công',
    alertDeleteAllSuccess: 'Xoá các phê duyệt thành công',
  },
  driverSection: {
    name: 'Tên tài xế',
    groupName: 'Tên nhóm',
    free: 'Phí tham gia',
    status: 'Trạng thái',

    list: 'Danh sách tài xế',

    pending: 'Đang chờ',
    approve: 'Phê duyệt',
    reject: 'Từ chối',
    rejected: 'Đã từ chối',
    delete: 'Xoá',
    search: 'Tìm kiếm',
    clear: 'Dọn',
    phoneNumber: 'Số điện thoại',
    gender: 'Giới tính',
    point: 'Số điểm',

    licenseCertificate: 'Bằng cấp',
    businessCertificate: 'Giấy phép hoạt động',
    shopInformation: 'Thông tin nhà thuốc',

  },
  alertSection: {
    fieldIsRequired: 'Trường này không được để trống',
    fieldIsNumber: 'Trường này phải là số',
    fieldIsDate: 'Ngày tháng không chính xác',
    fieldIsEmail: 'Trường này phải là email',
    fieldIsInteger: 'Trường này phải là số nguyên',
    fieldNumberIsMax: 'Giá trị không được lớn hơn {{number}}',
    fieldNumberIsMin: 'Giá trị không được nhỏ hơn {{number}}',
    fieldNumberInRange: 'Giá trị chỉ được trong khoảng từ {{from}} đến {{to}}',
    dateMustLargerThanNow: 'Ngày phải lớn hơn ngày hiện tại',
    imageRequired: 'Trường này không được để trống',
    passwordLengthIsMin: 'Mật khẩu tối thiểu {{number}} ký tự',
    passwordLengthIsMax: 'Mật khẩu tối đa {{number}} ký tự',
    fileSizeIsMin: 'Kích thước tệp tin không thể nhỏ hơn {{size}}',
    fileSizeIsMax: 'Kích thước tệp tin không thể lớn hơn {{size}}',
    fileMimetypeIsIncorrect: 'Tệp tin không đúng định dạng',
    fileMimetypeIsOnlyAllowed: 'Chỉ cho phép định dạng {{mimeTypes}}',
    phoneNumberWrongFormat: 'Số điện thoại chưa hợp lệ',
    cannotEnterMoreThanNumbersAfterDecimalPoint:
      'Không thể nhập quá {{number}} chữ số thập phân',
    emailIsUsedByOther: 'Email đã được sử dụng bởi người khác',
    phoneNumberIsUsedByOther: 'Số điện thoại đã được sử dụng bởi người khác',
  },
  GroupManagement: '',
  Delete: 'Xóa',
  edit: `Chỉnh sửa`,

  app: `ứng dụng`,
  user: `người dùng`,
  list: `danh sách`,
  shop: `cửa hàng`,
  blog: `blog`,
  post: `bài viết`,
  mail: `thư`,
  chat: `trò chuyện`,
  cards: `thẻ`,
  posts: `danh sách bài viết`,
  create: `tạo`,
  kanban: `bảng`,
  general: `chung`,
  banking: `ngân hàng`,
  booking: `đặt phòng`,
  profile: `hồ sơ`,
  account: `tải khoản`,
  product: `sản phẩm`,
  invoice: `hóa đơn`,
  details: `chi tiết`,
  checkout: `thanh toán`,
  calendar: `lịch`,
  analytics: `phân tích`,
  ecommerce: `thương mại điện tử`,
  management: `quản lý`,
  menu_level: `menu`,
  menu_level_2a: `menu cấp 2a`,
  menu_level_2b: `menu cấp 2b`,
  menu_level_3a: `menu cấp 3a`,
  menu_level_3b: `menu cấp 3b`,
  menu_level_4a: `menu cấp 4a`,
  menu_level_4b: `menu cấp 4b`,
  item_disabled: `mục vô hiệu`,
  item_label: `mục nhãn`,
  item_caption: `mục chú thích`,
  item_external_link: `mục liên kết ngoài`,
  description: `mô tả`,
  other_cases: `Trường hợp khác`,
  item_by_roles: `mục theo vai trò`,
  only_admin_can_see_this_item: `Chỉ có quản trị viên mới có thể nhìn thấy mục này`,
};

export default vi;
