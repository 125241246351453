import axios, { AxiosInstance } from 'axios'
import { decryptDataResponse, logDev } from 'src/utils'
import { appConfig } from '../../../config'
import { HandleResponseError, HttpRestService } from '../../index'
import { getAuthTokenAdmin } from '../token'

export const InstanceAxios: AxiosInstance = axios.create({
  baseURL: appConfig.API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-lang': 'vi'
  },
})

InstanceAxios.interceptors.request.use(config => {
  const tokenAuth = getAuthTokenAdmin()
  if (config.headers) {
    if (tokenAuth !== '') {
      config.headers['Authorization'] = 'Bearer ' + tokenAuth || ''
    }
  }
  return config
})

InstanceAxios.interceptors.response.use(
  res => {
    if (typeof res.data === 'string') {
      res.data = decryptDataResponse(res, { type: 'partner' })
    }

    logDev(`partner ${res?.config?.url} ${res?.status}`, res?.data)

    return res?.data
  },
  error => HandleResponseError(error, { type: 'partner' })
)

export const HttpServicePartner = new HttpRestService(InstanceAxios)
