import { useMutation } from 'react-query'

import {
    onLogin,
    onGetProfile,
    onLogout,
  } from '../api_functions/admin'

export function useLogin() {
  return useMutation(onLogin)
}

export function useLogout() {
  return useMutation(onLogout)
}

export function useProfile() {
  return useMutation(onGetProfile)
}
