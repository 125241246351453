import {
  decryptSession,
  encryptSession,
} from '../encode'

/**
 * SESSION_TOKEN: vùng nhớ lưu trữ các giá trị được định nghĩa trong LIST_KEY_TOKEN
 * SESSION_DATA: vùng nhớ lưu trữ các giá trị còn lại
 */

export const STORAGE_KEY = {
  SESSION_TOKEN_USER: 'session-token-1',
  SESSION_TOKEN_PARTNER: 'session-token-2',
  SESSION_DATA: 'session-data',
}

export const KEY_TOKEN_USER = {
  AUTH_TOKEN_USER: 'AUTH_TOKEN_USER',
  REFRESH_TOKEN_USER: 'REFRESH_TOKEN_USER',
  TOKEN_HANDSHAKE_USER: 'TOKEN_HANDSHAKE_USER',
  DATE_HANDSHAKE_LASTED_USER: 'DATE_HANDSHAKE_LASTED_USER',
  AES_KEY_USER: 'AES_KEY_USER',
  AES_IV_USER: 'AES_IV_USER',
}

export const KEY_TOKEN_PARTNER = {
  AUTH_TOKEN_PARTNER: 'AUTH_TOKEN_PARTNER',
  REFRESH_TOKEN_PARTNER: 'REFRESH_TOKEN_PARTNER',
  TOKEN_HANDSHAKE_PARTNER: 'TOKEN_HANDSHAKE_PARTNER',
  DATE_HANDSHAKE_LASTED_PARTNER: 'DATE_HANDSHAKE_LASTED_PARTNER',
  AES_KEY_PARTNER: 'AES_KEY_PARTNER',
  AES_IV_PARTNER: 'AES_IV_PARTNER',
}

class StorageServiceClass {
  set<T>(key: string, data: T): void {
    const storageKey = this.getStorageKey(key)
    const dataOld = localStorage.getItem(storageKey)
    var objectJson = decryptSession(dataOld || '')
    if (objectJson !== undefined && objectJson !== '') {
      objectJson[key] = data
    } else {
      objectJson = {}
      objectJson[key] = data
    }
    localStorage.setItem(storageKey, encryptSession(objectJson) || '')
  }

  get<T>(key: string): T {
    const storageKey = this.getStorageKey(key)
    const dataOld = localStorage.getItem(storageKey)
    var objectJson = decryptSession(dataOld || '')
    if (objectJson !== undefined && objectJson !== '') {
      return objectJson[key] as T
    }
    return '' as T
  }

  has(key: string): boolean {
    const storageKey = this.getStorageKey(key)
    const dataOld = localStorage.getItem(storageKey)
    var objectJson = decryptSession(dataOld || '')
    if (objectJson !== undefined && objectJson !== '') {
      if (objectJson[key] !== undefined) {
        return true
      }
    }
    return false
  }

  remove(key: string) {
    const storageKey = this.getStorageKey(key)
    const dataOld = localStorage.getItem(storageKey)
    var objectJson = decryptSession(dataOld || '')
    if (objectJson !== undefined && objectJson !== '') {
      delete objectJson[key]
      localStorage.setItem(storageKey, encryptSession(objectJson) || '')
    }
  }

  destroyData() {
    localStorage.setStorageSync(STORAGE_KEY.SESSION_DATA, '')
    this.startStorage()
  }

  destroyAll() {
    // app.prototype.$session.destroy()
    this.startStorage()
  }

  startStorage() {
    localStorage.setStorageSync(STORAGE_KEY.SESSION_TOKEN_PARTNER, '')
    localStorage.setStorageSync(STORAGE_KEY.SESSION_TOKEN_USER, '')
    localStorage.setStorageSync(STORAGE_KEY.SESSION_DATA, '')
  }

  private getStorageKey(key: string) {
    let storageKey = STORAGE_KEY.SESSION_DATA
    if (Object.values(KEY_TOKEN_PARTNER).includes(key)) {
      storageKey = STORAGE_KEY.SESSION_TOKEN_PARTNER
    }
    if (Object.values(KEY_TOKEN_USER).includes(key)) {
      storageKey = STORAGE_KEY.SESSION_TOKEN_USER
    }
    return storageKey
  }
}

export const StorageService = new StorageServiceClass()
