export const TABLE_HEAD = (translate: any) => [
  { id: 'driverName', label: translate('approveSection.name'), align: 'left' },
  { id: 'point', label: translate('approveSection.point'), align: 'center' },
  {
    id: 'bankAccountNumber',
    label: translate('approveSection.bankAccountNumber'),
    align: 'center',
  },
  {
    id: 'bankAccountOwnerName',
    label: translate('approveSection.bankAccountOwnerName'),
    align: 'center',
  },
  {
    id: 'bankName',
    label: translate('approveSection.bankName'),
    align: 'center',
  },
  {
    id: 'dateTime',
    label: translate('approveSection.dateTime'),
    align: 'center',
  },
  { id: 'status', label: translate('approveSection.status'), align: 'center' },
  { id: '' },
]

// export const INACTIVED = 'Pending'
