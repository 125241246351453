import {
  Button,
  Card,
  Container,
  IconButton,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material'
import { Helmet } from 'react-helmet-async'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs'
import { useSettingsContext } from 'src/components/settings'
import { APP_NAME } from 'src/config/constant'
import { useLocales } from 'src/locales'
import { PATH_ADMIN } from 'src/routes/paths'
import { ApproveStatus } from './constants/enum'
import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router'
import { TABLE_HEAD } from './constants'
import TableSelectedAction from 'src/components/table/TableSelectedAction'
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from 'src/components/table'
import Iconify from 'src/components/iconify'
import Scrollbar from 'src/components/scrollbar'
import { useDeleteApprove } from 'src/api/hooks/approve.query'
import ConfirmDialog from 'src/components/confirm-dialog'
import { useSnackbar } from 'notistack'
import useDebounce from 'src/hooks/useDebounce'
import { logDev } from 'src/utils'
import {
  ApproveWithdrawalTableRow,
  ApproveWithdrawalTableToolbar,
} from './components'
import { useGetListWithdraw } from 'src/api/hooks/payment.query'
import { useGetListGroup } from 'src/api/hooks/groups.query'
import { useAuthContext } from 'src/auth/useAuthContext'

function ApproveWithdrawalPage() {
  const { themeStretch } = useSettingsContext()
  const { enqueueSnackbar } = useSnackbar()
  const { translate } = useLocales()
  const listWithdraw = useGetListWithdraw()
  const deleteApprove = useDeleteApprove()

  const [filterName, setFilterName] = useState('')
  const [filterStatus, setFilterStatus] = useState(ApproveStatus.Pending)

  const [openConfirm, setOpenConfirm] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const { profile } = useAuthContext()

  const listGroup = useGetListGroup()
  const [groupId, setGroupId] = useState('')

  const [filterGroup, setFilterGroup] = useState({
    adminId: profile?.adminId,
    provinceId: '-1',
    districtId: '-1',
    wardsId: '-1',
    paging: {
      search: '',
      skip: 0,
      limit: 99,
    },
  })

  const { active = ApproveStatus.Pending } = queryString.parse(location.search)

  const isFiltered = filterName !== '' || filterStatus !== ApproveStatus.Pending

  const _TABLE_HEAD = TABLE_HEAD(translate)

  const [filter, setFilter] = useState({
    status: ApproveStatus.Pending,
    paging: {
      search: '',
      skip: 0,
      limit: 10,
    },
  })

  const [total, setTotal] = useState<any>(0)
  const [listData, setListData] = useState<any>([])
  const [listDataGroup, setListDataGroup] = useState<any>([])
  const filterDebounce = useDebounce(JSON.stringify(filter))
  const { selected, onSelectAllRows, onSelectRow, setSelected } = useTable()
  const idField = 'Id'

  // Get List Group
  const handleGetListGroup = async () => {
    const res: any = await listGroup.mutateAsync(filterGroup)
    setListDataGroup(res?.data?.data)
    setGroupId(
      res?.data?.data != null
        ? res?.data?.data.length > 0
          ? res?.data?.data[0].groupId
          : ''
        : ''
    )
  }

  const handleOnChangeGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupId(event.target.value)
    handleUpdateFilter({ groupId: event.target.value.trim(), skip: 0 })
  }

  const handleOpenConfirm = () => {
    setOpenConfirm(true)
  }

  const handleResetFilter = () => {
    setFilterName('')
    setFilterStatus(ApproveStatus.Pending)
    setFilter({
      ...filter,
      status: ApproveStatus.Pending,
      paging: {
        search: '',
        skip: 0,
        limit: 10,
      },
    })
  }

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value)
    handleUpdateFilter({ search: event.target.value.trim(), skip: 0 })
  }

  const handleUpdateFilter = (update: Object) => {
    const updatedPaging = { ...filter.paging, ...update }
    const updatedFilter = { ...filter, paging: updatedPaging }
    setFilter(updatedFilter)
  }

  const handleCloseConfirm = () => {
    setOpenConfirm(false)
  }

  /// useEffect
  // Get List Group
  useEffect(() => {
    handleGetListGroup()
  }, [filterGroup])

  useEffect(() => {
    let activeFilter: any = filter.status
    if (
      [
        ApproveStatus.Pending,
        ApproveStatus.Transferred,
        ApproveStatus.CancelTransfer,
      ]
    ) {
      activeFilter = active
    }

    setFilter({
      ...filter,
      status: activeFilter,
    })
  }, [active])

  useEffect(() => {
    setSelected([])
    listWithdraw.mutate(filter)
  }, [filterDebounce])

  useEffect(() => {
    if (listWithdraw.isSuccess) {
      const res: any = listWithdraw.data.data
      logDev(listWithdraw)
      setListData(res)
      setTotal(res?.total)
    }
  }, [listWithdraw.isSuccess])

  useEffect(() => {
    if (deleteApprove.isSuccess) {
      enqueueSnackbar(translate('approveSection.alertDeleteAllSuccess'))
      handleCloseConfirm()
      listWithdraw.mutate(filter)
      setSelected([])
    }
  }, [deleteApprove.isSuccess])

  return (
    <>
      <Helmet>
        <title>
          {translate('approve')} | {APP_NAME}
        </title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={translate('approveSection.list') || ''}
          links={[
            {
              name: translate('approve') || '',
              href: PATH_ADMIN.root,
            },
            {
              name: translate('approveSection.list') || '',
            },
          ]}
        />
        <Card>
          <ApproveWithdrawalTableToolbar
            listGroup={listDataGroup}
            groupId={groupId}
            isFiltered={isFiltered}
            filterName={filterName}
            onFilterName={handleFilterName}
            onResetFilter={handleResetFilter}
            onChangeGroup={handleOnChangeGroup}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              numSelected={selected.length}
              rowCount={listData?.data?.length || 0}
              onSelectAllRows={checked =>
                onSelectAllRows(
                  checked,
                  (listData?.data || []).map((row: any) => row?.[idField])
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  headLabel={_TABLE_HEAD}
                  numSelected={selected.length}
                  onSelectAllRows={checked =>
                    onSelectAllRows(
                      checked,
                      (listData?.data || []).map((row: any) => row?.[idField])
                    )
                  }
                />

                <TableBody sx={{ minHeight: 500 }}>
                  {listWithdraw.isSuccess &&
                    Array.isArray(listData?.data || []) &&
                    (listData?.data || []).map((row: any, index: number) => (
                      <ApproveWithdrawalTableRow
                        key={index}
                        row={row}
                        inActive={filter.status}
                        selected={selected.includes(row?.[idField])}
                        onSelectRow={() => onSelectRow(row?.[idField])}
                        handleRefreshList={() => listWithdraw.mutate(filter)}
                      />
                    ))}

                  {listWithdraw.isSuccess && listData?.data?.length > 0 && (
                    <TableEmptyRows
                      height={36}
                      emptyRows={
                        filter.paging?.limit - listData?.data?.length || 0
                      }
                    />
                  )}

                  {listWithdraw.isSuccess && (
                    <TableNoData isNotFound={listData.data?.length === 0} />
                  )}

                  {listWithdraw.isLoading &&
                    [...new Array(filter.paging?.limit)].map((item, i) => (
                      <TableRow key={i}>
                        <TableCell colSpan={_TABLE_HEAD.length + 1}>
                          <Skeleton height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
        </Card>
        <TablePaginationCustom
          count={total}
          page={Math.floor(filter?.paging?.skip / filter?.paging?.limit)}
          rowsPerPage={filter?.paging?.limit}
          onPageChange={(e, page) =>
            handleUpdateFilter({
              skip: filter?.paging?.limit * page,
            })
          }
          onRowsPerPageChange={e =>
            handleUpdateFilter({ skip: 0, limit: +e.target.value })
          }
        />
      </Container>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content={translate('approveSection.confirmDeleteAll')}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              deleteApprove.mutate({
                approveId: selected,
              })
            }}
          >
            {translate('delete')}
          </Button>
        }
      />
    </>
  )
}
export default ApproveWithdrawalPage
