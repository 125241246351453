import CryptoJS from 'crypto-js'
import moment from 'moment'
import queryString from 'query-string'
import { appConfig } from '../config'
import { IDecryptDataOptions, decryptData } from 'src/api/config/encode/encode'

export function paginateArray<T>(array: T[], page: number, limit: number): T[] {
  return array.slice((page - 1) * limit, page * limit) as T[]
}

export const createRedirectUrl = (): string => {
  const { pathname, search } = window.location

  if (pathname === '/' && !search) return ''

  return queryString.stringify({
    redirectUrl: `${pathname}${search}`,
  })
}

export const getTimeNews = (dateTime: string) => {
  return moment(dateTime).locale('vi').format('ll')
}

export const generateSignature = (jsonBody: any) => {
  const timestamp = new Date().getTime()
  const encodedWord = CryptoJS.enc.Utf8.parse(JSON.stringify(jsonBody))
  const dataBody = CryptoJS.enc.Base64.stringify(encodedWord)
  const dataTemp = 'v0:' + timestamp + ':' + dataBody

  let signature = CryptoJS.HmacSHA256(
    dataTemp,
    appConfig.CLIENT_SECRET
  ).toString(CryptoJS.enc.Hex)
  signature = 'v0=' + signature

  return [timestamp, signature]
}

export const FORMAT_TIME = {
  DATE1: 'DD/MM/YYYY',
}

/**
 * Decrypt data response from api
 * @param {*} response : String
 * @param {*} url : String
 * @returns
 */
export function checkIfHandshakeEmptyOrExpired(token: string) {
  var check = false
  try {
    if (token === undefined || token === null || token === '') {
      check = true
    } else {
      check = false
    }
  } catch (ex) {
    // throw 'Error checkIfHandshakeEmptyOrExpired' + ex
  }
  return check
}


export function decryptDataResponse(
  response: any,
  customOptions: IDecryptDataOptions = {}
) {
  try {
    if (response === undefined) return ''
    if (response.data !== undefined) {
      var objectJson = decryptData(response.data, customOptions)
    } else {
      objectJson = decryptData(response, customOptions)
    }
    // if (objectJson === undefined) throw 'SERVER_CHANGE_KEY'
    return objectJson
  } catch (ex) {
    // throw 'SERVER_CHANGE_KEY'
  }
}

export const getUrlImage = (url: String): string => {
  if (url === '') return ''
    return appConfig.API_URL + url;
}