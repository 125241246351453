import { REST_API_AUTH } from "../api_paths"
import { InstanceAxios } from "../config/axios/admin"
import { logDev } from "src/utils"

export const onLogin = async (requestModel: any): Promise<any> => {
  const route: string = REST_API_AUTH.LOGIN.uri

  const body = {
    phone: requestModel?.data?.phone,
    email: requestModel?.data?.email,
    password: requestModel?.data?.password,
  }
  return InstanceAxios.post(route, body)
}

export const onGetProfile = async (): Promise<any> => {
  const route: string = REST_API_AUTH.GET_PROFILE.uri

  return InstanceAxios.get(route)
}

export const onLogout = async (requestModel: any): Promise<any> => {
  const route: string = REST_API_AUTH.LOGOUT.uri

  const body = {
    refreshToken: requestModel?.refreshToken,
  }

  return InstanceAxios.post(route, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}