import { useEffect, useState } from 'react'
// @mui
import {
  Avatar,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Link as MuiLink,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
// @types
// components
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog'
import Iconify from 'src/components/iconify/Iconify'
import Label from 'src/components/label/Label'
import MenuPopover from 'src/components/menu-popover/MenuPopover'
import { useLocales } from 'src/locales'
import { fNumber } from 'src/utils/formatNumber'
import { format } from 'date-fns'
import { useVerifyWithdraw } from 'src/api/hooks/payment.query'
import { ApproveStatus } from '../constants/enum'
import { logDev } from 'src/utils'

// ----------------------------------------------------------------------

type Props = {
  row: any
  selected: boolean
  onSelectRow: VoidFunction
  handleRefreshList: () => void
  inActive: string
}

export default function ApproveWithdrawalTableRow({
  row,
  selected,
  inActive,
  onSelectRow,
  handleRefreshList,
}: Props) {
  const {
    point,
    avatar,
    dateTime,
    status,
    bankAccountNumber,
    bankAccountOwnerName,
    bankName,
  } = row

  const { translate } = useLocales()
  const { enqueueSnackbar } = useSnackbar()
  const verifyWithdraw = useVerifyWithdraw()

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null)
  const [isOpenApproveDialog, setOpenApproveDialog] = useState(false)
  const [isOpenRejectDialog, setOpenRejectDialog] = useState(false)

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget)
  }

  const handleClosePopover = () => {
    setOpenPopover(null)
  }

  logDev('row ', row)

  useEffect(() => {
    if (verifyWithdraw.isSuccess) {
      enqueueSnackbar(translate('approveSection.alertApproveSuccess'))
      setOpenPopover(null)
      handleRefreshList()
    }
  }, [verifyWithdraw.isSuccess])

  return (
    <>
      <TableRow hover>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <Link
            to={`/driver/detail?id=${
              row?.driver != null ? row?.driver['driverId'] : ''
            }`}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                alt={row?.driver != null ? row?.driver['name'] : ''}
                src={avatar}
              />
              <MuiLink variant="subtitle2" color="text.primary">
                {row?.driver != null ? row?.driver['fullName'] : ''}
              </MuiLink>
            </Stack>
          </Link>
        </TableCell>
        <TableCell align="center">{fNumber(point)}</TableCell>
        <TableCell align="center">{bankAccountNumber}</TableCell>
        <TableCell align="center">{bankAccountOwnerName}</TableCell>
        <TableCell align="center">{bankName}</TableCell>
        <TableCell align="center">
          {format(new Date(dateTime), 'HH:mm dd/MM/yyyy')}
        </TableCell>
        <TableCell align="center">
          <Label
            variant="soft"
            color={
              status === ApproveStatus.CancelTransfer
                ? 'error'
                : status === ApproveStatus.Transferred
                ? 'success'
                : 'warning'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {status === ApproveStatus.CancelTransfer
              ? translate('approveSection.rejected')
              : status === ApproveStatus.Transferred
              ? translate('approveSection.approved')
              : translate('approveSection.pending')}
          </Label>
        </TableCell>

        <TableCell align="right">
          <IconButton
            color={openPopover ? 'inherit' : 'default'}
            onClick={handleOpenPopover}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            setOpenApproveDialog(true)
            handleClosePopover()
          }}
        >
          <Iconify icon="mdi:success-bold" color="primary.main" />
          <Typography variant="subtitle2" color="primary.main">
            {translate('approve')}
          </Typography>
        </MenuItem>
        {inActive !== ApproveStatus.CancelTransfer && (
          <MenuItem
            onClick={() => {
              setOpenRejectDialog(true)
              handleClosePopover()
            }}
          >
            <Iconify icon="ic:baseline-close" color="error.main" />
            <Typography variant="subtitle2" color="error.main">
              {translate('InActive')}
            </Typography>
          </MenuItem>
        )}

        {/* <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true)
            handleClosePopover()
          }}
        >
          <Iconify icon="eva:trash-2-outline" color="error.main" />
          <Typography variant="subtitle2" color="error.main">
            {translate('delete')}
          </Typography>
        </MenuItem> */}
      </MenuPopover>

      {/* Reject Dialog */}
      <ConfirmDialog
        open={isOpenRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        title={translate(`approveSection.confirmTitle`)}
        content={translate(`approveSection.confirmReject`)}
        action={
          <Button
            variant="contained"
            disabled={verifyWithdraw.isLoading}
            color="error"
            onClick={() => {
              verifyWithdraw.mutate({
                withdrawId: row?.['withdrawId'],
                status: 'CancelTransfer',
              })
            }}
          >
            {translate(`InActive`)}
          </Button>
        }
      />

      {/* Approve Dialog */}
      <ConfirmDialog
        open={isOpenApproveDialog}
        onClose={() => setOpenApproveDialog(false)}
        title={translate(`approveSection.confirmTitle`)}
        content={translate(`approveSection.confirmApprove`)}
        action={
          <Button
            variant="contained"
            disabled={verifyWithdraw.isLoading}
            color="primary"
            onClick={() => {
              verifyWithdraw.mutate({
                withdrawId: row?.['withdrawId'],
                status: 'Transferred',
              })
            }}
          >
            {translate(`approve`)}
          </Button>
        }
      />
    </>
  )
}
