// routes
import { PATH_ADMIN } from '../../../routes/paths'
// components
import SvgColor from '../../../components/svg-color'

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
)

const ICONS = {
  group: icon('ic_groups'),

  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  approve: icon('ic_approve'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // {
      //   title: "Dashboard",
      //   path: PATH_ADMIN.general.app,
      //   icon: ICONS.dashboard,
      // },
      {
        title: 'Quản lý nhóm',
        path: PATH_ADMIN.general.group,
        icon: ICONS.group,
      },
      {
        title: 'Phê duyệt tài xế',
        path: PATH_ADMIN.general.approve,
        icon: ICONS.approve,
      },
      {
        title: 'Phê duyệt rút điểm',
        path: PATH_ADMIN.general.approveWithdrawal,
        icon: ICONS.banking,
      },
      {
        title: 'Quản lý tài xế',
        path: PATH_ADMIN.general.driver,
        icon: ICONS.user,
      },
    ],
  },
]

export default navConfig
