import { useMutation } from 'react-query'
import { approveDriver, deleteApprove, getDriverDetail, getListApprove } from '../api_functions/approve'

export function useGetListApprove() {
  return useMutation(getListApprove)
}

export function useGetDriverDetail() {
  return useMutation(getDriverDetail)
}

export function useApproveDriver() {
  return useMutation(approveDriver)
}

export function useDeleteApprove() {
  return useMutation(deleteApprove)
}
