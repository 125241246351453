import { isPhoneNumber } from 'src/utils/string'
import { useAuthContext } from '../../auth/useAuthContext'

export default function useAuthLoginForm() {
  const { login } = useAuthContext()
  
  
  const handleLogin = async (data: any) => {
    const isPhone = isPhoneNumber(data?.phoneNumber)
    const password = data?.password

    const phoneNumber = '+84' + data.phoneNumber?.replace(/^(0|84|\+84)/g, '')

    const object = {
      phone: isPhone ? phoneNumber : '',
      email: "",
      password: password,
    }
    await login(object)
  }

  return {
    handleLogin,
  }
}
