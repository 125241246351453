import {  REST_API_PAYMENT } from '../api_paths'
import { InstanceAxios } from '../config/axios/admin'

export const getListWithdraw = (body?: any) => {
  const route: string = REST_API_PAYMENT.GET_LIST_WITHDRAW.uri
  return InstanceAxios.post(route, body)
}

export const verifyWithdraw = (body?: any) => {
  const route: string = REST_API_PAYMENT.VERIFY_WITHDRAW.uri
  return InstanceAxios.post(route, body)
}

