// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Xin chào`,
    description: `Bạn cần giúp đỡ? \n Vui lòng kiểm tra tài liệu của chúng tôi.`,
    documentation: `documentation`,
  },
  navMenu: {
    dashboard: 'Dashboard',
    groupManagement: 'Group management'
  },
  groups: {
    groupList: 'Group list',
    addGroup: 'Add group',

    name: 'Group name',
    status: 'Status',
    fee: 'Fee',
    numberMembers: 'Number members',

    createGroupTitle: 'Add group',
    updateGroupTitle: 'Update group',

    description: 'Group description',
    content: 'Group content',
    regulations: 'Group regulations',
    groupImage: 'Image',
    address: 'Address',
    province: 'Province/City',
    district: 'District',
    wards: 'Wards',

    alertCreateGroupSuccess: 'Create a successful group',
    alertUpdateGroupSuccess: 'Group update successful',

    alertFieldRequired: 'This field cannot be left blank',
    alertFieldIsNumber: 'This field must be number',
    fieldIsInteger: 'This field must be an integer',
    fieldNumberIsMin: 'The value cannot be less {{number}}',
    alertShopImageRequired: 'Group photos cannot be blank',

    confirmDelete: 'Do you want to delete this group?',
    confirmTitle: 'Confirm',
    alertDeleteSuccess: 'Successfully deleted group',
    alertDeleteError: 'Group deletion failed',

    create: 'Create',
    update: 'Update',
    close: 'Close',
  },
  userDetail: {
    liveAt: 'Live in',
    addressAt: 'Residential address',
    accountInformation: 'Account information',
    information: 'Information',
    numPoint: 'Point',
    rate: 'Đánh giá',
    workplace: 'Work at',
  },
  approveSection: {
    list: 'Danh sách phê duyệt',

    pending: 'Đang chờ',
    approve: 'Phê duyệt',
    reject: 'Từ chối',
    rejected: 'Đã từ chối',
    delete: 'Xoá',
    search: 'Tìm kiếm',
    clear: 'Dọn',

    licenseCertificate: 'Bằng cấp',
    businessCertificate: 'Giấy phép hoạt động',
    shopInformation: 'Thông tin nhà thuốc',

    confirmTitle: 'Xác nhận',
    confirmApprove: 'Bạn có muốn phê duyệt người này không?',
    confirmReject: 'Bạn có muốn từ chối phê duyệt này không?',
    confirmDelete: 'Bạn có muốn xoá phê duyệt này không?',
    confirmDeleteAll: 'Bạn có muốn xoá những phê duyệt này không?',
    alertApproveSuccess: 'Phê duyệt thành công',
    alertRejectSuccess: 'Từ chối thành công',
    alertDeleteSuccess: 'Xoá phê duyệt thành công',
    alertDeleteAllSuccess: 'Xoá các phê duyệt thành công',
  },
  accountPage: {
    firstName: 'First name',
    lastName: 'Last name',
    birthdate: 'Birth date',
    gender: 'Gender',
    avatar: 'Avatar',
    info: 'Description',
    address: 'Address',
    email: 'Email',
    phoneNumber: 'Phone Number',
    save: 'Save',
    General: 'Personal information',
    changePassword: 'Change password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    Updatesuccess: 'Updated successfully',

    allowed: 'Allow formatting *.jpeg, *.jpg, *.png, *.gif',
    unFomat: 'Malformed',
  },
  alertSection: {
    fieldIsRequired: 'Trường này không được để trống',
    fieldIsNumber: 'Trường này phải là số',
    fieldIsDate: 'Ngày tháng không chính xác',
    fieldIsEmail: 'Trường này phải là email',
    fieldIsInteger: 'Trường này phải là số nguyên',
    fieldNumberIsMax: 'Giá trị không được lớn hơn {{number}}',
    fieldNumberIsMin: 'Giá trị không được nhỏ hơn {{number}}',
    fieldNumberInRange: 'Giá trị chỉ được trong khoảng từ {{from}} đến {{to}}',
    dateMustLargerThanNow: 'Ngày phải lớn hơn ngày hiện tại',
    imageRequired: 'Trường này không được để trống',
    passwordLengthIsMin: 'Mật khẩu tối thiểu {{number}} ký tự',
    passwordLengthIsMax: 'Mật khẩu tối đa {{number}} ký tự',
    fileSizeIsMin: 'Kích thước tệp tin không thể nhỏ hơn {{size}}',
    fileSizeIsMax: 'Kích thước tệp tin không thể lớn hơn {{size}}',
    fileMimetypeIsIncorrect: 'Tệp tin không đúng định dạng',
    fileMimetypeIsOnlyAllowed: 'Chỉ cho phép định dạng {{mimeTypes}}',
    phoneNumberWrongFormat: 'Số điện thoại chưa hợp lệ',
    cannotEnterMoreThanNumbersAfterDecimalPoint:
      'Không thể nhập quá {{number}} chữ số thập phân',
    emailIsUsedByOther: 'Email đã được sử dụng bởi người khác',
    phoneNumberIsUsedByOther: 'Số điện thoại đã được sử dụng bởi người khác',
  },
  Delete: 'Xóa',
  edit: `Chỉnh sửa`,

  app: `app`,
  user: `user`,
  list: `list`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
