import { Suspense, lazy, ElementType } from 'react'
// components
import LoadingScreen from '../components/loading-screen'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')))
export const RegisterPage = Loadable(
  lazy(() => import('../pages/auth/RegisterPage'))
)
export const VerifyCodePage = Loadable(
  lazy(() => import('../pages/auth/VerifyCodePage'))
)
export const NewPasswordPage = Loadable(
  lazy(() => import('../pages/auth/NewPasswordPage'))
)
export const ResetPasswordPage = Loadable(
  lazy(() => import('../pages/auth/ResetPasswordPage'))
)
export const AccountPage = Loadable(
  lazy(() => import('../pages/admin/account/AccountPage'))
)

// ADMIN
export const GroupPage = Loadable(
  lazy(() => import('../pages/admin/groups/GroupPage'))
)
export const ApprovePage = Loadable(
  lazy(() => import('../pages/admin/approve/ApprovePage'))
)
export const ApproveDetailPage = Loadable(
  lazy(() => import('../pages/admin/profile/ApproveDetailPage'))
)
export const DriverPage = Loadable(
  lazy(() => import('../pages/admin/driver/DriverPage'))
)

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')))
export const Page403 = Loadable(lazy(() => import('../pages/Page403')))
export const Page404 = Loadable(lazy(() => import('../pages/Page404')))
export const MaintenancePage = Loadable(
  lazy(() => import('../pages/MaintenancePage'))
)
