// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_ADMIN = '';
const ROOTS_AUTH = '/auth';
const ROOTS_USER = '/user';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  general: {
    dashboard: path(ROOTS_ADMIN, '/coming-soon'),
    group: path(ROOTS_ADMIN, '/list-group'),
    approve: path(ROOTS_ADMIN, '/approve'),
    approveWithdrawal: path(ROOTS_ADMIN, '/approve-withdrawal'),
    driver: path(ROOTS_ADMIN, '/driver'),

  },
  user: {
    profile: path(ROOTS_USER, '/profile'),
    account: path(ROOTS_USER, '/account'),
  },
  mail: {
    root: path(ROOTS_ADMIN, '/mail'),
    all: path(ROOTS_ADMIN, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_ADMIN, '/chat'),
    new: path(ROOTS_ADMIN, '/chat/new'),
    view: (name: string) => path(ROOTS_ADMIN, `/chat/${name}`),
  },
};

export const PATH_DOCS = {
  root: 'http://lichchuan.vn',
  changelog: 'http://lichchuan.vn',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
