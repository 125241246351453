import { REST_API_GROUPS } from '../api_paths'
import { InstanceAxios } from '../config/axios/admin'

export const getListGroups = (body?: any) => {
  const route: string = REST_API_GROUPS.GET_LIST.uri
  return InstanceAxios.post(route, body)
}

export const createGroup = (body?: any) => {
  const route: string = REST_API_GROUPS.CREATE.uri
  return InstanceAxios.post(route, body)
}

export const updateGroup = (body?: any) => {
  const route: string = REST_API_GROUPS.UPDATE.uri
  return InstanceAxios.post(route, body)
}

export const updateGroupImage = (body?: { [key: string]: any }) => {
  const formData = new FormData()
  for (let key in body) {
    formData.append(key, body[key])
  }
  const route: string = REST_API_GROUPS.UPDATE_IMAGE.uri
  return InstanceAxios.post(route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getListPharmacist = (filter?: any) => {
  const route: string = REST_API_GROUPS.GET_LIST_PHARMACIST.uri
  return InstanceAxios.post(route, filter)
}

export const deleteGroup = (data?: any) => {
  const route: string = REST_API_GROUPS.DELETE_GROUP.uri
  return InstanceAxios.post(route, data)
}
export const getListPhamarcist = (filter?: any) => {
  const route: string = REST_API_GROUPS.GET_LISTPHAMARCIST.uri
  return InstanceAxios.post(route, filter)
}
