import { AxiosError } from 'axios'
import { REST_API_AUTH } from 'src/api/api_paths'
import { logDev } from 'src/utils'
import Snackbar from 'src/utils/snackbar'
import { IHandleResponseOptions } from './helper'

export class BaseResponse {
  code: number
  data: any
  message: String
  result: boolean

  constructor(code: number, data: any, message: String, result: boolean) {
    this.code = code
    this.data = data
    this.message = message
    this.result = result
  }
}

export const HandleResponseError = (
  error: AxiosError<any>,
  options: IHandleResponseOptions = {}
): Promise<any> => {
  logDev('Response Error: ', error?.response)
  const res = error?.response
  if (res) {
    var statusCode = res?.data?.statusCode
    logDev('statusCode ', res?.data?.message)
    switch (statusCode) {
      case 400:
        if (!!res?.data?.message) {
          Snackbar.error(res?.data?.message)
        }
        break
      case 401:
        if (res?.config?.url !== REST_API_AUTH.GET_PROFILE.uri) {
          if (res?.data?.message) {
            Snackbar.error(res?.data?.message)
          } else {
            Snackbar.error('Unauthorized')
          }
        }
        break
      case 403:
        console.log(res)
        break;
      case 404:
        if (!!res?.data?.message) {
          Snackbar.error(res?.data?.message)
        }
        break
      case 500:
        if (!!res?.data?.message) {
          Snackbar.error(res?.data?.message)
        }
        break
      case 503:
        console.log(res)
        break;
      default:
        Snackbar.error('Error')
    }
  } else if (error.request) {
    console.log('No response received from server')
  } else {
    console.log('Error', error.message)
  }
  return Promise.reject(error)
}
