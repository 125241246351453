import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// routes
import { PATH_ADMIN } from "../../../routes/paths";
// components
import { CustomAvatar } from "../../../components/custom-avatar";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { profile } = useAuthContext();

  return (
    <Link
      component={RouterLink}
      to={PATH_ADMIN.user.account}
      underline="none"
      color="inherit"
    >
      <StyledRoot>
        <CustomAvatar
          src={profile?.avatar}
          alt={profile?.lastName}
          name={profile?.lastName}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {profile?.fullName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            {profile?.phone}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
